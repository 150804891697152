import {
  Auth0GridUser,
  IHttpSearchResponse
} from '@gr/shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

const useUsers = (): UseAxiosResult<IHttpSearchResponse<Auth0GridUser>> => {
  return useAxios<IHttpSearchResponse<Auth0GridUser>>({
    url: 'auth0-get-users',
    method: 'POST'
  });
};

export default useUsers;
