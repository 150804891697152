import { Button, ButtonVariantEnum, EmailIcon, Info, TableList, TextInput } from '@Wonder-Cave/ui';
import { IDropdownValue } from '@Wonder-Cave/ui/dist/Autocomplete';
import { Filter } from '@Wonder-Cave/ui/dist/Table/Filters';
import { useAuth0 } from '@auth0/auth0-react';
import { axiosPost } from '@gr/portal/authAxios';
import useRoles from '@gr/portal/hooks/useRoles';
import { convertEnumToReadableString, downloadFileFromUrl } from '@gr/portal/providers/utility.provider';
import {
  Auth0ExportUsersRequest,
  Auth0GridUser,
  Auth0Role,
  Auth0User,
  FilterDataTypeEnum,
  FilterOperatorEnum,
  IHttpResponse,
  ISearchFilter
} from '@gr/shared/models';
import { isStaffUserOrHigher } from '@gr/shared/utils';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useUsers from '../../hooks/useUsers';
import ClientDropdown from '../shared/Form/Dropdowns/NewClientDropdown';
import UploadUsersModal from './UploadUsersModal';
import { defaultUsersTableOptions, getUsersColumns } from './types';

const Users = () => {
  const [searchText, setSearchText] = useState('');
  const [showUpload, setShowUpload] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [tableOptions, setTableOptions] = useState(defaultUsersTableOptions);
  const history = useHistory();
  const { user: auth0User } = useAuth0();
  const isSuperAdmin = useRoles([Auth0Role.GR_ADMIN]);
  const [{ data: userData, loading: userLoading, error: userError }, searchUsers] = useUsers();
  const [users, setUsers] = useState<any>([]);

  const mapUsers = () => {
    return userData?.data.records.map((user) => ({
      ...user,
      role: user?.app_metadata?.memberships?.find(m => m.organization === auth0User?.organization_name)?.roles?.[0],
    })).filter((u: Auth0User) => {
      if (tableOptions.filters.length > 0) {
        let valid = true;
        for (let i = 0; i < tableOptions.filters?.length; i++) {
          switch (tableOptions.filters[i].fieldName) {
            case 'email':
              valid = valid && (u.email as string).includes(tableOptions.filters[i].value);
              break;
            case 'role':
              valid = valid && (u.app_metadata.memberships.find(m => m.organization === auth0User?.organization_name)?.roles.includes(tableOptions.filters[i].value))!;
              break;
            case 'client':
              valid = valid && u.clients.includes(tableOptions.filters[i].value);
              break;
            default:
              valid = valid;
              break;
          }
        }
        return valid;
      } else {
        return true;
      }
    });
  };

  useEffect(() => {
    const users = mapUsers();
    setUsers(users);
  }, [userData]);

  useEffect(() => {
    const searchText = tableOptions.filters.find((filter) => filter.fieldName === 'email')?.value;
    setSearchText(searchText ?? '');
    const users = mapUsers();
    setUsers(users);
  }, [tableOptions]);

  const columns = getUsersColumns(
    { name: (item: Auth0GridUser) => history.push(`users/${item?.user_id}`) },
    {
      name: (item: Auth0GridUser) => <div>
        <h5 className='mb-2 text-medium-gray'>{item?.app_metadata?.memberships?.[0]?.roles?.[0]?.replaceAll('_', ' ')}</h5>
        <div className='hover:text-wc-blue hover:underline'>{(item.given_name ?? '') + ' ' + (item.family_name ?? '')}</div>
        <div className="flex items-center body-text-small text-medium-gray"><EmailIcon /><div className='ml-2'>{item.email}</div></div>
      </div>,
      // blocked: (item: Auth0User) => <ToggleSwitch checkedColor='bg-grass' checked={!item.blocked} onChange={() => { }} />,
      clients: (item: Auth0GridUser) => {
        const clients = isStaffUserOrHigher(item?.app_metadata?.memberships?.[0]?.roles?.[0]) ? [{ name: 'All Clients ' }] : item?.clients?.length > 0 ? item?.clients : [{ name: '-' }];
        return <div className='flex items-center whitespace-break-spaces'>{clients.slice(0, 3).map(c => c.name).join(', ')} <Info text={clients.map(c => c.name).join(', ')} infoElement={<div className='ml-2 text-black underline cursor-pointer body-text-bold'>{clients.length > 3 ? `+${clients.length - 3}` : ''}</div>} /></div>;
      },
      createdAt: (item: Auth0GridUser) => moment(item.created_at).format('L'),
      requestedBy: (item: Auth0GridUser) => <div className='pr-2'>{item.app_metadata.requestedBy}</div>
    }
  );



  const handleRefetch = async () => {
    try {
      await searchUsers();
    } catch (error) { }
  };

  const exportUsers = async () => {
    try {
      const request: Auth0ExportUsersRequest = {
        users
      };
      setExporting(true);
      const {
        data: { data: signedUrl },
      } = await axiosPost<IHttpResponse<string>>('/auth0-export-users', request);

      downloadFileFromUrl(signedUrl);
    } catch (err) {
      console.error(err);
    } finally {
      setExporting(false);
    }
  };

  return (
    <div>
      <div className="flex items-center mb-2 px-28">
        <h1 className='mr-4'>Users</h1>
        <div className='w-2/6 ml-auto mr-4'>
          <TextInput
            id="usersEmailSearch"
            name="usersEmailSearch"
            placeholder="Email"
            search
            value={searchText}
            onChange={(e) => {
              if (
                e.target.value.length < 1 &&
                tableOptions.filters.find((x) => x.fieldName === 'email')?.value?.length > 0
              ) {
                setTableOptions((prevState) => {
                  const filteredPrevStateFilters = prevState.filters.filter((filter) => filter.fieldName !== 'email');
                  filteredPrevStateFilters.push({
                    fieldName: 'email',
                    dataType: FilterDataTypeEnum.STRING,
                    operator: FilterOperatorEnum.CONTAINS,
                    value: '',
                  });
                  prevState.filters = filteredPrevStateFilters;
                  return {
                    ...prevState,
                    filters: [
                      ...prevState.filters
                    ],
                  };
                });
              }
              setSearchText(e.target.value);
            }}
            onEnter={(e) => {
              setTableOptions((prevState) => {
                // remove old search text from prev state
                const filteredPrevStateFilters = prevState.filters.filter((filter) => filter.fieldName !== 'email');
                prevState.filters = filteredPrevStateFilters;
                return {
                  ...prevState,
                  filters: [...prevState.filters,
                  ... (searchText.length > 0 ? [
                    {
                      fieldName: 'email',
                      dataType: FilterDataTypeEnum.STRING,
                      operator: FilterOperatorEnum.CONTAINS,
                      value: searchText,
                    },
                  ] : [])],
                };
              });
            }}
          />
        </div>
        <div className="flex pt-3 space-x-4">
          <Button
            id="users-upload"
            variant={ButtonVariantEnum.PRIMARY}
            onClick={() => setShowUpload(true)}
          >
            UPLOAD USERS
          </Button>

          <Button variant={ButtonVariantEnum.TERTIARY} onClick={() => history.push('users/new')}>
            INVITE USER
          </Button>
          <div className='flex flex-col'>
            <Button variant={ButtonVariantEnum.SECONDARY} isLoading={exporting} onClick={exportUsers}>
              EXPORT DATA
            </Button>
            {tableOptions.filters.some(f => f.fieldName === 'role' || f.fieldName === 'email') && <h5 className='mx-auto mt-1 text-tiny text-medium-gray'>Export Filtered Data</h5>}
          </div>
        </div>
      </div>

      <TableList
        columns={columns}
        items={users?.length > 0 ? users?.slice(tableOptions.pagination.skip, (tableOptions.pagination.skip + tableOptions.pagination.take)) : []}
        shimmer
        loading={userLoading}
        limit={10}
        paginate
        onPaginate={(page) => {
          setTableOptions((prevState) => ({
            ...prevState,
            pagination: {
              ...prevState.pagination,
              skip: page * prevState.pagination.take,
            },
          }));
        }}
        totalCount={users?.length ?? 0}
        filters={[{
          label: 'Client',
          id: 'clients',
          dataType: FilterDataTypeEnum.STRING,
          operator: FilterOperatorEnum.EQUALS,
          customDropdown: (value, onChange) => {
            return <ClientDropdown
              placeholder='Search for a client'
              multiple={false}
              gray={true}
              noLabel={true}
              value={value}
              onChange={(newValue) => {
                onChange(newValue as IDropdownValue);
              }}
              renderError={false}
            />;
          }
        }, {
          label: 'Role',
          id: 'role',
          dataType: FilterDataTypeEnum.STRING,
          operator: FilterOperatorEnum.EQUALS,
          options: Object.entries(Auth0Role).filter(([_, role]) => {
            return !(!isSuperAdmin && [Auth0Role.GR_ADMIN].includes(role));
          }).map(([key, value]) => {
            return {
              label: convertEnumToReadableString(value),
              value: value
            };
          }),
          optionsLabel: 'Select Role',
        }]}
        onFilter={(filters: Filter[]) => {
          if (filters.length > 0) {
            const formattedFilters: ISearchFilter[] = filters.map(f => {
              return [{
                fieldName: f.id,
                dataType: f.dataType as FilterDataTypeEnum,
                operator: f.operator,
                value: f.selectedOption?.value
              }];
            }).flat();
            const searchTextFilter = tableOptions.filters.find((option) => option.fieldName === 'email');
            if (searchTextFilter) formattedFilters.push(searchTextFilter);
            setTableOptions((prevState) => ({
              ...prevState,
              filters: [
                ...formattedFilters
              ]
            }));
          } else {
            const searchTextFilter = tableOptions.filters.find((option) => option.fieldName === 'email');
            let updatedTableOptions = defaultUsersTableOptions;
            if (searchTextFilter) updatedTableOptions = { ...updatedTableOptions, filters: [searchTextFilter] };
            setTableOptions(updatedTableOptions);
          }
        }}
      />

      {/* <Table
        columns={columns}
        items={users}
        tableSearchOptions={tableOptions}
        onSearchOptionChange={handleSearchOptionChange}
        filter
        shimmer
        paginate
        sort={true}
        count={usersTotalCount}
        loading={userLoading}
        booleanLabels={[{ fieldName: 'active', displayValues: { trueLabel: 'Active', falseLabel: 'Inactive' } }]}
        filterColumns={filterColumns}
        customFilters={customFilters}
        filterDropdownOptions={usersFilterDropdownOptions}
      /> */}
      <UploadUsersModal show={showUpload} setShow={setShowUpload} onUpload={handleRefetch} />
    </div>
  );
};

export default Users;
